export class RequestTypeModel {
    public Id: string;
    public Name: string;
    public Description: string;
    public CreatedBy: string;
    public CreationDate: string;
    public RecordStatus: boolean;
    public UserName: string;
  
  }

  export class RequestCategoryModel {
    public Id: string;
    public Name: string;
    public Description: string;
    public CreatedBy: string;
    public CreationDate: string;
    public RecordStatus: boolean;
    public UserName: string;
  
  }

  export class ItemViewModel {
    constructor(id?: number, name?: string) {
      this.Id = id;
      this.Name = name;
    }
    
    public Id: number;
    public Name: string;
  }
  export class StringItemViewModel{
    constructor(id?: string, name?: string) {
      this.Id = id;
      this.Name = name;
    }
    
    public Id: string;
    public Name: string;
  }
  export class RequestMasterModel {
    public Id: number;
    public RequestCode: string;
    public RequestTitle: string;
    public Description: string;
    public FK_SenderItemVM: StringItemViewModel;
    public FK_ReceiverItemVM: StringItemViewModel;
    public RequestDate: Date;
    public FK_CategoryItemVM: ItemViewModel;
    public FK_TypeItemVM: ItemViewModel;
    public Remarks: string;
    public Attachment: string;
    public IsApproved: boolean;
    public RejectedReason: string;
    public RejectedDate: Date;
    public CreatedBy: string;
    public CreatedDate: Date;
    public RecordStatus: boolean;
    public FK_ClientItemVM: ItemViewModel;
    public file: any;
  
  }

  export class RequestMasterFormModel {
    public FK_AssignToItemVM: StringItemViewModel;
    public FK_CategoryItemVM: ItemViewModel;
    public FK_TypeItemVM: ItemViewModel;
    public RequestCodes: string[];
  }