import { Injectable } from '@angular/core';
import { LocalService } from './local.service';
import { ClaimKeys } from '../_helpers/claimkeys.class';

@Injectable({
  providedIn: 'root'
})
export class ClaimsService {

  public claimKeys = new ClaimKeys();
  constructor(private _localService: LocalService) { }

  checkClaim(claim: string) {
    // var user = this._localService.get('ussr');
    return this._localService.get('ussr').Claims.includes(claim);
  }

}
