import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '../../_services/authentication.service';
import { LocalService } from '../../_services/local.service';
import { Config } from '../../_helpers/config.class';
import { AssignPricingModelRequest, ConfirmPricingModelRequest, FleetClientBillingInformation, PaymentAfterLogout } from './payment-model';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  headers: HttpHeaders = new HttpHeaders();

  constructor(private http: HttpClient, _authenticationService: AuthenticationService,
    private _localService: LocalService) {
    this.headers.set('Content-Type', 'application/x-www-form-urlencoded');
    this.headers.set('Authorization', 'Bearer ' + _authenticationService.getToken())
  }

  public getPaymentCards(skip: number, pageSize: number, query: string, hfId: string, geoLvlCode: string, hfType: string, stockId: number, searchTerm: string) {
    return this.http.post(`${Config.getControllerUrl('Payment', 'GetPaymentCards')}`, { Skip: skip, PageSize: pageSize, Query: query, HfId: hfId, GeoLvlCode: geoLvlCode, HfType: hfType, StockId: stockId ,Search: searchTerm});
  }

  public removePaymentCard(id: number) {
    return this.http.post(`${Config.getControllerUrl('Payment', 'DeletePaymentCard')}`, { Id: id });
  }

  public addPaymentCard(token: string, name: string) {
    return this.http.post(`${Config.getControllerUrl('Payment', 'AddPaymentCard')}`, { Code: token, Type: name });
  }

  public confirmPaymentCard(id: number) {
    return this.http.post(`${Config.getControllerUrl('Payment', 'ConfirmPaymentCardAddition')}`, { Id: id });
  }
  public setDefaultPaymentCard(id: number) {
    return this.http.post(`${Config.getControllerUrl('Payment', 'SetDefaultPaymentCard')}`, { Id: id });
  }
  public getBillingDetail() {
    return this.http.get(`${Config.getControllerUrl('Payment', 'GetFleetClientBillingDetails')}`);
  }

  public getTransactionHistory(skip: number, pageSize: number, query: string, hfId: string, geoLvlCode: string, hfType: string, stockId: number, searchTerm: string) {
    return this.http.post(`${Config.getControllerUrl('Payment', 'GetTransactionHistory')}`, { Skip: skip, PageSize: pageSize, Query: query, HfId: hfId, GeoLvlCode: geoLvlCode, HfType: hfType, StockId: stockId ,Search: searchTerm});
  }
  public saveBillingInformation(obj: FleetClientBillingInformation) {
    return this.http.post(`${Config.getControllerUrl('Payment', 'UpdateBillingDetails')}`, obj);
  }
  public getPaymentCardsForPayment() {
    let id = 0;
    return this.http.post(`${Config.getControllerUrl('Payment', 'GetPaymentCardsForPayment')}`, { Id: id });
  }
  public getPricingModels() {
    debugger
    let id = 0;
    return this.http.post(`${Config.getControllerUrl('Payment', 'GetPricingModels')}`, { Id: id });
  }

  public assignPricingModel(obj: AssignPricingModelRequest) {
    return this.http.post(`${Config.getControllerUrl('Payment', 'AssignPricingModelAsync')}`, obj);
  }

  public confirmPricingModelAssignment(obj: ConfirmPricingModelRequest) {
    return this.http.post(`${Config.getControllerUrl('Payment', 'ConfirmPricingModelAssignmentAsync')}`, obj);
  }

  public cancelSubscription() {
    debugger
    let id = 0;
    return this.http.post(`${Config.getControllerUrl('Payment', 'CancelSubscription')}`, { Id: id });
  }


  public getPricingModelsAfterLogout() {
    debugger
    let id = 0;
    return this.http.post(`${Config.getControllerUrl('Payment', 'GetPricingModelsAfterLogout')}`, { Id: id });
  }

  public addPaymentAfterLogout(obj:PaymentAfterLogout) {
    return this.http.post(`${Config.getControllerUrl('Payment', 'AddPaymentAfterLogout')}`, obj);
  }
}
