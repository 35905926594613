import { ItemViewModel } from "../stock/MedStock.class";


export class CoustomerContrectViewMode {
  public Id: number;
  public Title: string;
  public ContrectDate: Date;
  public FK_Coustomer_Account: ItemViewModel;
  public StartDate: Date;
  public EndDate: Date;
  public Status: boolean;
  public ShiftHour: number;
  public NoOfsolutions: number;
  public ContrectReate: number;
  public ContractType: string;
  public Accessories: any[];
}

export class ContrectAccessoriesViewModel {
  public Id: number;
  public FK_Contrect: number;
  public FK_Item_Category: ItemViewModel;
  public Qty: number;
  public GivenBy: string;
  public Rate: number;
  public Remarks: string;

}

export class SupplierContrectViewMode {
  public Id: number;
  public Title: string;
  public ContrectDate: Date;
  public FK_Supplier_Account: ItemViewModel;
  public StartDate: Date;
  public EndDate: Date;
  public Attachement: string;
  // public FK_ClientVM: ItemViewModel;
  // public Status: boolean;
  // public ShiftHour: number;
  // public NoOfsolutions: number;
  // public ContrectReate: number;
  // public ContractType: string;
  // public Accessories: any[];
}

export class SupplierAccessoriesViewModel {
  public Id: number;
  public FK_Contrect: number;
  public FK_Item_Category: ItemViewModel;
  public Qty: number;
  public GivenBy: string;
  public Rate: number;
  public Remarks: string;

}
export class EmployeeAssiginingModel {
  public Id: number;
  public FK_Contrect: ItemViewModel;
  public FK_Employee: ItemViewModel;
  public StartDate: Date;
  public EndDate: Date;
  public AssiginingDate: Date;
  public Mode: string;
  public EmployeeRate: number;




}
export class CoustomerCashMastModel {
  Id: number;
  FK_Contrect: ItemViewModel;
  Cash_Receving_Type: string;
  FromDate: Date;
  ToDate: Date;
  Amount: number | null;
  RecoverAmount: number | null;
  PendingAmount: number | null;
  CreatedBy: string;
  CreationDate: Date;
  UpdatedBy: string;
  UpdationDate: Date;
  RecordStatus: boolean | null;
  Remarks: string;
  EmpList: CoustomerCashDetail[];
  public excelFile: any;
  TransactionDate: Date;
}

export class CoustomerCashDetail {
  Id: number;
  FK_Mast: number | null;
  FK_Emp: ItemViewModel;
  CashInHand: number | null;
  CashReturn: number | null;
  CashRemaning: number | null;
  CreatedBy: string;
  CreationDate: Date | string | null;
  UpdatedBy: string;
  UpdationDate: Date | string | null;
  RecordStatus: boolean | null;
  IsLock: boolean | null;

}
export class CusCashReturn {
  Fk_Emp: ItemViewModel;
  Fk_Acc: ItemViewModel;
  Payable: number;
  Amount: number;
  Remaning: number;
  CreationDate = new Date();
}

export class DirectCashCollectionViewModel {
  public Id: number;
  public FK_EmployeId: ItemViewModel;
  public FK_CollectionAccount_Id: ItemViewModel;
  public FK_PayableAccount_Id: ItemViewModel;
  public FK_Client_Id: ItemViewModel;
  public StartDate: Date;
  public EndDate: Date;
  public TransactionDate: Date;
  public Amount: number;
  public Remarks: string;
  public Attachment: string;

  public file: any;
}

export class BullkReturnCODViewModel {
  public bulkPaymentList: OrderCODViewModel[];
  public Fk_EmployeAccount_Id: ItemViewModel;
  public FK_DabitAccount_Id: ItemViewModel;
  public FK_CreditAccount_Id: ItemViewModel;
  public FK_CustomerAccount_Id: ItemViewModel;
  public FK_Client_Id: ItemViewModel;
  public PaymentMethod: ItemViewModel;
  public TansactionDate: Date;
  public Createdby: string;
  public Attachement: string;
  public Tottal: number;
  public TotalCOD: number;
  public RecevedCOD: number;
  public DifferanceCOD: number;
  public BankAdjustement: number;
  public CustomerShare: number;
  public CompanyShare: number;
  public file: any;
  public Remarks: string;
}
export class OrderCODViewModel {
  public Id: number;
  public TansactionDate: Date;
  public OrderId: string;
  public FK_Customer_Id: ItemViewModel;
  public Fk_EmployeAccount_Id: ItemViewModel;
  public COD: number;
  public TotalCOD: number;
  public CustomerShare: number;
  public CompanyShare: number;
  public RemainingAmount: number;
  public PaidAmount: number;
  public PaymentMethod: number;
  public PaymentMethodName: string;
  public IsReturn: boolean
  public FK_CreditAccount_Id: ItemViewModel;
  public FK_DabitAccount_Id: ItemViewModel;
  public FK_COD_Id: ItemViewModel;
  public createdBy: string;
  public RefId: string;
}

export enum ECODSettlementType {
  POS = 1,
  Cash = 2,
  Customer = 3
}

export class DCCSettlementViewModel {
  public Id: number;
  public TotalAmount: number;
  public RefId: string;
  public FK_DCCSettlement: ItemViewModel;
  public FK_ClientId: ItemViewModel;
  public TransactionDate: Date
  public EmpSettlementList: Array<any>;
}


export class CCODMastModel {
  public Id: number
  public FK_Contrect: ItemViewModel;
  public Customer: ItemViewModel;
  public FK_ClientVM: ItemViewModel;
  public Cash_Receving_Type: string;
  public endDate: Date;
  public FromDate: Date;
  public CreationDate: Date;
  public ToDate: Date
  public Amount: number;
  public RecoverAmount: number;
  public PendingAmount: number;
  public Remarks: string;
  public EmpList: Array<any>;
  public excelFile: any;
}

export class FourPLInvoiceMasterViewModel {
  constructor() {
    
  }
  
  public Id: number
  public FK_Supplier_Contract: ItemViewModel;
  public FK_Dhn_Invoice_Master: ItemViewModel;
  public FK_Supplier_Account: ItemViewModel;
  public FK_Supplier_Expense: ItemViewModel;
  public Cash_Receving_Type: string;
  public TransactionDate: Date;
  public StartDate: Date;
  public EndDate: Date
  public InvoiceAmount: number=0;
  public VAT: number;
  public Remarks:string;
  public FourPLInvoiceDetailList:Array<FourPLInvoiceDetailViewModel>;
}

export class FourPLInvoiceDetailViewModel {
  constructor( ) {
  }
  
  public Id:number
  public Fk_Invoice_master_Id:ItemViewModel
  public Fk_Employee_Id:ItemViewModel
  public FK_dhn_detail_Id:ItemViewModel
  public EmployeeRate:number
  public TotalOrders:number
  public TotalAmount:number
  public Total:number
  public VatAmount:number
  public TotalVatAmount:number
  public  IsLock :boolean;
  public CreatedBy:number
  public UpdatedBy:number
  public CreatedOn:Date
  public UpdateOn:Date
  public Fk_Contract_Id:ItemViewModel

}

export class FourPlInvoiceTotal {
  constructor( ) {
   
  }
  
  public TotalOrders:number=0;
  public TotalEmployeeRate:number=0;
  public Total:number=0;
  public VatAmountTotal:number=0;
  public TotalInvoiceAmount:number=0;
  
}
