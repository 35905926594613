import { Injectable, Type } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AuthenticationService } from '../../_services/authentication.service';
import { LocalService } from '../../_services/local.service';
import { Config } from '../../_helpers/config.class';
import { RequestMasterModel, RequestTypeModel } from './request-model';
@Injectable()
export class RequestService {
  headers: HttpHeaders = new HttpHeaders();
  constructor(private http: HttpClient, _authenticationService: AuthenticationService,
    private _localService: LocalService) {
    this.headers.set('Content-Type', 'application/x-www-form-urlencoded');
    this.headers.set('Authorization', 'Bearer ' + _authenticationService.getToken())
  }

  public getType(skip: number, pageSize: number, query: string, hfId: string, geoLvlCode: string, hfType: string, stockId: number, searchTerm: string) {
    return this.http.post(`${Config.getControllerUrl('Request', 'GetTypes')}`, { Skip: skip, PageSize: pageSize, Query: query, HfId: hfId, GeoLvlCode: geoLvlCode, HfType: hfType, StockId: stockId ,Search: searchTerm});
  }

  public saveType(type: RequestTypeModel) {
    debugger
    return this.http.post(`${Config.getControllerUrl('Request', 'SaveType')}`, type);
  }

  public getCategory(skip: number, pageSize: number, query: string, hfId: string, geoLvlCode: string, hfType: string, stockId: number, searchTerm: string) {
    return this.http.post(`${Config.getControllerUrl('Request', 'GetCategory')}`, { Skip: skip, PageSize: pageSize, Query: query, HfId: hfId, GeoLvlCode: geoLvlCode, HfType: hfType, StockId: stockId ,Search: searchTerm});
  }

  public saveCategory(category: RequestTypeModel) {
    return this.http.post(`${Config.getControllerUrl('Request', 'SaveCategory')}`, category);
  }

  public RemoveType(id: number) {
    return this.http.post(`${Config.getControllerUrl('Request', 'RemoveType')}`, { Id: id });
  }

  public RemoveCategory(id: number) {
    return this.http.post(`${Config.getControllerUrl('Request', 'RemoveCategory')}`, { Id: id });
  }

  public LoadRequestForm() {
    return this.http.get(`${Config.getControllerUrl('Request', 'GetRequestFrom')}`);
  }

  public saveRequest(obj: RequestMasterModel) {
    const formData = new FormData();
    formData.append('file', obj.file);
    formData.append('Obj', JSON.stringify(obj));
    return this.http.post(`${Config.getControllerUrl('Request', 'SaveRequest')}`, formData);
  }

  public getSendingRequest(skip: number, pageSize: number, query: string, hfId: string, geoLvlCode: string, hfType: string, stockId: number, searchTerm: string) {
    return this.http.post(`${Config.getControllerUrl('Request', 'GetSendingRequest')}`, { Skip: skip, PageSize: pageSize, Query: query, HfId: hfId, GeoLvlCode: geoLvlCode, HfType: hfType, StockId: stockId ,Search: searchTerm});
  }

  public getRecievingRequest(skip: number, pageSize: number, query: string, hfId: string, geoLvlCode: string, hfType: string, stockId: number, searchTerm: string) {
    return this.http.post(`${Config.getControllerUrl('Request', 'GetRecievingRequest')}`, { Skip: skip, PageSize: pageSize, Query: query, HfId: hfId, GeoLvlCode: geoLvlCode, HfType: hfType, StockId: stockId ,Search: searchTerm});
  }
  public RemoveRequest(id: number) {
    return this.http.post(`${Config.getControllerUrl('Request', 'RemoveRequest')}`, { Id: id });
  }
  public GetRequestById(id: number) {
    return this.http.post(`${Config.getControllerUrl('Request', 'GetRequestById')}`, { Id: id });
  }

  public ApprovedRequest(id: number) {
    return this.http.post(`${Config.getControllerUrl('Request', 'ApprovedRequest')}`, { Id: id });
  }

  public RejectRequest(obj: RequestMasterModel) {
    return this.http.post(`${Config.getControllerUrl('Request', 'RejectRequest')}`, { Id: obj.Id, Type: obj.RejectedReason });
  }
}
