export class FleetClientBillingInformation{
  public BillingAddress1: string;
  public BillingAddress2: string;
  public BillingCountry: string;
  public BillingCity: string;
  public BillingState: string;
  public BillingZipCode: string;
    
}

export class AssignPricingModelRequest{
  public PricingModelId: number;
  public PricingMode: string;
  public PaymentCardType: string;
  public PaymentCardKey: string;
}

export class ConfirmPricingModelRequest{
  public FleetClientPricingModelId: number;
  public FleetClientPaymentCardId: number;
}

export class PaymentAfterLogout{
  public PricingModelId: number;
  public PricingMode: string;
  public PaymentCardType: string;
  public PaymentCardKey: string;
  public FleetClientPricingModelId: number;
  public FleetClientPaymentCardId: number;

  public CardHolderName:string;
  public Last4Digits: string;
  public ClientId: number;
  public Token: string;
  public UserId: string;
}

