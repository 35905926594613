import { ItemViewModel } from "../stock/MedStock.class";

export class EmpSalaryMastModel {
  public Id: number;
  public FK_Contrect: ItemViewModel;
  public FK_Coustomer_Account: ItemViewModel;
  public FK_Exp_Acc: ItemViewModel;
  public FK_Revinew_Account: ItemViewModel;
  public VatPer: number | null;
  public FromDate: Date;
  public ToDate: Date;
  public Total: number | null;
  public NetTotal: number | null;
  public VatAmount: number | null;
  public Remarks: string;
  public CreatedBy: string;
  public CreationDate: Date;
  public UpdatedBy: string;
  public UpdationDate: Date | string | null;
  public RecordStatus: boolean | null;
  public NoDays: number | null;
  public DutyHour: number | null;
  public SaleryType: string;
  public FK_SaleInvRef: number
  public OffDays: number;
  public PaidAmount: number;
  public TermOfPayments: string;
  public Attachment: string;
  public file: any;
  public EmpList: EmpSalaryDetailModel[];
  public TransactionDate: Date;
}


export class EmpSalaryDetailModel {
  public Id: number;
  public FK_SaleryMast: number | null;
  public FK_Emp: ItemViewModel;
  public Gross: number | null;
  public Vat: number | null;
  public Total: number | null;
  public CSahre: number | null;
  public Empshare: number | null;
  public SMothely: number | null;
  public SalaryAmount: number | null;
  public SWorkDays: number | null;
  public SPerDay: number | null;
  public SAbsentDays: number | null;
  public SPerDayDeduction: number | null;
  public SAbsDeduction: number | null;
  public SFines: number | null;
  public SAdjustement: number | null;
  public Stip: number | null;
  public SOTPerHour: number | null;
  public SOT: number | null;
  public SOTSalery: number | null;
  public STotal: number | null;
  public CreatedBy: string;
  public CreationDate: Date | string | null;
  public UpdatedBy: string;
  public UpdationDate: Date | string | null;
  public RecordStatus: boolean | null;
  public IsLock: boolean | null;

}
